

import { RequestUser } from '@/tool/util'

export function Login(formData) {
    return RequestUser("post",{
        url: "login",
        data: formData
    })
}

export function ToConsole(user_id) {
    return RequestUser("post",{
        url: "login/" + user_id,
    })
}

export function BackAdmin() {
    return RequestUser("post",{
        url: "login/origin",
    })
}

export function Logout() {
    return RequestUser("delete",{
        url: "login",
    })
}

export function GetUserInfo() {
    return RequestUser("get",{
        url: "user/me",
    })
}

// 用户列表接口

export function LstUser(formData) {
    return RequestUser("get",{
        url: `user`,
        data: formData
    })
}

export function CreateUser(formData) {
    return RequestUser("post",{
        url: `user`,
        data: formData
    })
}


export function UserCount() {
    return RequestUser("get",{
        url: `user/count`,
    })
}

export function ReadUser(id) {
    return RequestUser("get",{
        url: `user/${id}`,
    })
}

export function UpdateUser(id,formData = {}) {
    delete formData.delete_time
    delete formData.create_time
    delete formData.update_time
    return RequestUser("put",{
        url: `user/${id}`,
        data: formData
    })
}

export function UpdateUserPassword(id,formData = {}) {
    return RequestUser("put",{
        url: `password/${id}`,
        data: formData
    })
}

export function UpdateMyPassword(formData = {}) {
    return RequestUser("put",{
        url: `password/my`,
        data: formData
    })
}


export function DeleteUser(id) {
    return RequestUser("delete",{
        url: `user/${id}`,
    })
}


