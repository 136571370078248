<template>
    <div class="header-container">
        <a-layout-header class="header" :style="{ position: 'fixed', zIndex: 1000, width: '100%' }">
            <div class="content">
                <div class="sitename">
                    <span>{{ title }}</span> 
                </div>
                <div class="userinfo">
                    <div class="logout-a" v-if="isBack != userinfo.id" @click="handleBackAdmin">
                        返回后台
                    </div>
                    <div class="username overhide" @click="visible = true">
                        {{ userinfo.name }}
                    </div>
                    <div class="logout" title="安全退出" @click="logOut">
                        <poweroff-outlined />
                    </div>
                </div>
            </div>
        </a-layout-header>

        <a-modal v-model:visible="visible" title="修改密码" @ok="handleOk" @cancel="handleCancel" width="100%" wrapClassName="full-modal">
            <a-spin tip="Loading..." :spinning="loading">
                <a-form ref="formData" :rules="formDataRules" layout="horizontal" :model="PasswordData" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-row justify="center">
                        <a-col :xs="24" :lg="8">
                            <a-form-item label="原始密码" name="old_password">
                                <a-input v-model:value="PasswordData.old_password" type="password" placeholder="请输入原始密码" />
                            </a-form-item>
                        </a-col>
                        
                    </a-row>
                    <a-row justify="center">
                        <a-col :xs="24" :lg="8">
                            <a-form-item label="新密码" name="password">
                                <a-input v-model:value="PasswordData.password" type="password" placeholder="请输入新密码" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
            </a-spin>
        </a-modal>
    </div>
</template>


<script>
    import { UpdateMyPassword,BackAdmin } from '@/api/user/user';
    export default {
        data() {
            return {
                labelCol: { span: 5 },
                wrapperCol: { span: 14 },
                visible: false,
                loading: false,
                basic: {},
                PasswordData: {},
                formDataRules: {
                    old_password: [
                        { required: true, message: "请输入原始密码" },
                        { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'change'},
                    ],
                    password: [
                        { required: true, message: "请输入新密码" },
                        { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'change'},
                    ],
                },
                title: this.$baseconfig.sitename,
                userinfo: this.$store.state.userinfo || {},
                formData: JSON.parse(JSON.stringify(this.$store.state.userinfo)),
                isBack: this.$cookies.get("Origin-Id")
            }
        },
        created() {
            
        },
        methods: {
            handleBackAdmin() {
                let _this = this
                _this.$confirm({
                    title: "确定返回后台吗？",
                    okText: '确认',
                    cancelText: '取消',
                    onOk() {
                        BackAdmin().then(() => {
                            window.location.href = "/"
                        })
                    }
                });
            },
            handleCancel() {
                let _this = this
                _this.formData = JSON.parse(JSON.stringify(this.$store.state.userinfo))
            },
            handleOk() {
                let _this = this
                _this.$refs['formData'].validate().then(() => {
                    // if(_this.loading) {
                    //     return
                    // }
                    // _this.loading = true
                    UpdateMyPassword(_this.PasswordData).then((result) => {
                        _this.loading = false
                        if(result.code == 200) {
                            _this.$message.success({
                                content: "密码修改成功，正在退出，请重新登入",
                                onClose() {
                                    _this.doLogOut()
                                }
                            });
                        } else {
                            _this.$message.error({
                                duration: 1,
                                content: "原始密码错误",
                            });
                        }
                    })
                }).catch((error) => {
                    _this.loading = false
                    console.log(error)
                })
            },
            doLogOut() {
                let _this = this
                _this.$store.dispatch("Logout").then((result) => {
                    _this.$message.success({
                        duration: 1,
                        content: "退出成功",
                        onClose() {
                            _this.$router.push({
                                path: "/login"
                            })
                        }
                    })
                })
            },
            logOut() {
                let _this = this
                _this.$confirm({
                    title: "确定退出当前账号吗？",
                    okText: '确认',
                    cancelText: '取消',
                    onOk() {
                        _this.doLogOut()
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
.header-container {
    user-select: none;
    .ant-layout-header {
        padding: 0 !important;
        height: 50px !important;
        line-height: 50px !important;
    }
    .content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        .sitename {
            height: 100%;
            color: #FFF;
            padding: 0 10px;
            font-size: 20px;
            font-weight: bold;
        }

        @media screen and (orientation: landscape){
            .sitename {
                width: 200px;
                text-align: center;
            }
        }
        .userinfo {
            color: #FFF;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            .username {
                width: 100px;
                text-align: center;
                cursor: pointer;
            }
            .logout,.recharge {
                height: 100%;
                width: 50px;
                text-align: center;
                cursor: pointer;
                transition-duration: .5s;
            }
            .logout-a {
                padding: 0 10px;
                cursor: pointer;
            }
            .username:hover,.logout:hover,.recharge:hover,.logout-a:hover {
                background-color: #44505E;
                transition-duration: .5s;
            }
        }
    }
}
</style>

