import axios from 'axios'
import { Modal,message } from 'ant-design-vue';

const service = axios.create({
    timeout: 15000,
    headers: {"Content-Type": "application/json;charset=UTF-8"},
    withCredentials: true, //允许跨域请求
})


service.interceptors.request.use(function (config) {
    const base = config.base
    config.baseURL = BaseConfig[base]
    config.data = JSON.stringify(config.data)
    return config;
}, function (error) {
    return Promise.reject(error);
});


// 添加响应拦截器
service.interceptors.response.use(function (response) {
    var result = response.data ? response.data : { code: response.status, message: {} }
    return result;
}, function (error) {
    if(error.code == "ECONNABORTED") {
        Modal.error({
            title: '提示',
            content: error.message,
        })
    }
    var response = error.response.data
    message.error({
        content: response.message || '接口未知错误',
    });
    setTimeout(() => {
        message.destroy() 
    }, 1500);
    return Promise.reject(response);
});

export default service