import { createRouter, createWebHashHistory} from 'vue-router';
import Layout from '@/layout';
import cookies from 'vue-cookies'

const R = {
    "super": require("./admin"),
    "common": require("./user")
}
const routes = [
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        children: R[cookies.get("Role")]
    },
    {
        path: '/login',
        component: () => import("@/views/login.vue"),
        name: 'login',
    },
    {
		path: '/404',
        component: () => import("@/views/error/404.vue"),
		name: '404',
	},
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/error/404.vue'),
    },
    {
		path:'/redirect',
		component:Layout,
		hidden:true,
		children:[
			{
				path:'/redirect/:path(.*)',
				component: (resolve) => require(['@/views/redirect'], resolve)
			}
		]
	},
]

export default createRouter({
    history: createWebHashHistory(),
    routes
})