module.exports = [
    {
        path: '/dashboard',
        component: () => import(`@/views/users/dashboard/index.vue`),
        name: 'dashboard',
        meta: {
            title: '控制檯',
            menu_type: 2,
            is_show: 1,
            icon: "DesktopOutlined"
        }
    },
    {
        component: () => import("@/views/users/setup/index"),
        path: "/users/setup",
        name: "users/setup",
        auth: "",
        meta: {
            title: "Token管理",
            menu_type: 2,
            is_show: 1,
            icon: "ToolOutlined"
        },
    },
    {
        path: "/users/domain",
        name: "users/domain",
        auth: "",
        meta: {
            title: "域名管理",
            menu_type: 1,
            is_show: 1,
            icon: "ToolOutlined"
        },
        children: [
            {
                component: () => import("@/views/users/domain/dnspod/index"),
                path: "/users/domain/dnspod",
                name: "users/domain/dnspod",
                meta: {
                    title: "DNSPod",
                    menu_type: 2,
                    is_show: 1,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/domain/route53/index"),
                path: "/users/domain/route53",
                name: "users/domain/route53",
                meta: {
                    title: "Route53",
                    menu_type: 2,
                    is_show: 1,
                    icon: ""
                }
            },
        ]
    },
    {
        component: () => import("@/views/users/dns/dnspod/index"),
        path: "/users/dns/dnspod",
        name: "users/dns/dnspod",
        auth: "",
        meta: {
            title: "DNS記錄-DNSPod",
            menu_type: 2,
            is_show: 0,
            icon: "ToolOutlined"
        },
    },
    {
        component: () => import("@/views/users/dns/route53/index"),
        path: "/users/dns/route53",
        name: "users/dns/route53",
        auth: "",
        meta: {
            title: "DNS記錄-Route53",
            menu_type: 2,
            is_show: 0,
            icon: "ToolOutlined"
        },
    },
    {
        path: "/users/cdn",
        name: "users/cdn",
        auth: "",
        meta: {
            title: "CDN業者",
            menu_type: 1,
            is_show: 1,
            icon: "ThunderboltOutlined"
        },
        children: [
            {
                component: () => import("@/views/users/cdn/huawei/edit"),
                path: "/users/cdn/huawei/add",
                name: "users/cdn/huawei/add",
                meta: {
                    title: "添加華為雲CDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/huawei/edit"),
                path: "/users/cdn/huawei/edit",
                name: "users/cdn/huawei/edit",
                meta: {
                    title: "編輯華為雲CDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/huawei/index"),
                path: "/users/cdn/huawei",
                name: "users/cdn/huawei",
                meta: {
                    title: "華為雲",
                    menu_type: 2,
                    is_show: 1,
                    icon: "CodepenCircleOutlined"
                }
            },
            {
                component: () => import("@/views/users/cdn/baishan/edit"),
                path: "/users/cdn/baishan/add",
                name: "users/cdn/baishan/add",
                meta: {
                    title: "添加白山雲CDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/baishan/edit"),
                path: "/users/cdn/baishan/edit",
                name: "users/cdn/baishan/edit",
                meta: {
                    title: "編輯白山雲CDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/baishan/index"),
                path: "/users/cdn/baishan",
                name: "users/cdn/baishan",
                meta: {
                    title: "白山雲",
                    menu_type: 2,
                    is_show: 1,
                    icon: "CodepenCircleOutlined"
                }
            },
            {
                component: () => import("@/views/users/cdn/wangsu/edit"),
                path: "/users/cdn/wangsu/add",
                name: "users/cdn/wangsu/add",
                meta: {
                    title: "添加網宿CDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/wangsu/edit"),
                path: "/users/cdn/wangsu/edit",
                name: "users/cdn/wangsu/edit",
                meta: {
                    title: "編輯網宿CDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/wangsu/index"),
                path: "/users/cdn/wangsu",
                name: "users/cdn/wangsu",
                meta: {
                    title: "網宿",
                    menu_type: 2,
                    is_show: 1,
                    icon: "SlackOutlined"
                }
            },
            {
                component: () => import("@/views/users/cdn/aliyun/edit"),
                path: "/users/cdn/aliyun/add",
                name: "users/cdn/aliyun/add",
                meta: {
                    title: "添加阿里雲CDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/aliyun/edit"),
                path: "/users/cdn/aliyun/edit",
                name: "users/cdn/aliyun/edit",
                meta: {
                    title: "編輯阿里雲CDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/aliyun/index"),
                path: "/users/cdn/aliyun",
                name: "users/cdn/aliyun",
                meta: {
                    title: "阿里雲",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AliyunOutlined"
                }
            },
            {
                component: () => import("@/views/users/cdn/tencent/edit"),
                path: "/users/cdn/tencent/add",
                name: "users/cdn/tencent/add",
                meta: {
                    title: "添加騰訊雲CDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/tencent/edit"),
                path: "/users/cdn/tencent/edit",
                name: "users/cdn/tencent/edit",
                meta: {
                    title: "編輯騰訊雲CDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/tencent/index"),
                path: "/users/cdn/tencent",
                name: "users/cdn/tencent",
                meta: {
                    title: "騰訊雲",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AntCloudOutlined"
                }
            },
            {
                component: () => import("@/views/users/cdn/amazon/edit"),
                path: "/users/cdn/amazon/add",
                name: "users/cdn/amazon/add",
                meta: {
                    title: "添加AmazonCDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/amazon/edit"),
                path: "/users/cdn/amazon/edit",
                name: "users/cdn/amazon/edit",
                meta: {
                    title: "編輯AmazonCDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/amazon/index"),
                path: "/users/cdn/amazon",
                name: "users/cdn/amazon",
                meta: {
                    title: "Amazon",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AmazonOutlined"
                }
            },
            {
                component: () => import("@/views/users/cdn/conversant/edit"),
                path: "/users/cdn/conversant/add",
                name: "users/cdn/conversant/add",
                meta: {
                    title: "添加ConversantCDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/conversant/edit"),
                path: "/users/cdn/conversant/edit",
                name: "users/cdn/conversant/edit",
                meta: {
                    title: "編輯ConversantCDN",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/cdn/conversant/index"),
                path: "/users/cdn/conversant",
                name: "users/cdn/conversant",
                meta: {
                    title: "Conversant",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AmazonOutlined"
                }
            },

            {
                component: () => import("@/views/users/cdn/akamai1/index"),
                path: "/users/cdn/akamai",
                name: "users/cdn/akamai",
                meta: {
                    title: "Akamai",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AmazonOutlined"
                }
            },
            // {
            //     component: () => import("@/views/users/cdn/akamai/index"),
            //     path: "/users/cdn/akamai",
            //     name: "users/cdn/akamai",
            //     meta: {
            //         title: "akamai",
            //         menu_type: 2,
            //         is_show: 1,
            //         icon: "AmazonOutlined"
            //     }
            // },
            {
                component: () => import("@/views/users/cdn/cloudflare/index"),
                path: "/users/cdn/cloudflare",
                name: "users/cdn/cloudflare",
                meta: {
                    title: "cloudflare",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AmazonOutlined"
                }
            },
        ]
    },
    {
        path: "/users/ssl",
        name: "users/ssl",
        auth: "",
        meta: {
            title: "SSL證書",
            menu_type: 1,
            is_show: 1,
            icon: "BookOutlined"
        },
        children: [
            {
                component: () => import("@/views/users/ssl/huawei/edit"),
                path: "/users/ssl/huawei/add",
                name: "users/ssl/huawei/add",
                meta: {
                    title: "添加華為雲證書SSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/huawei/edit"),
                path: "/users/ssl/huawei/edit",
                name: "users/ssl/huawei/edit",
                meta: {
                    title: "編輯華為雲證書SSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/huawei/index"),
                path: "/users/ssl/huawei",
                name: "users/ssl/huawei",
                meta: {
                    title: "華為雲",
                    menu_type: 2,
                    is_show: 1,
                    icon: "CodepenCircleOutlined"
                }
            },
            {
                component: () => import("@/views/users/ssl/baishan/edit"),
                path: "/users/ssl/baishan/add",
                name: "users/ssl/baishan/add",
                meta: {
                    title: "添加白山雲證書SSL1",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/baishan/edit"),
                path: "/users/ssl/baishan/edit",
                name: "users/ssl/baishan/edit",
                meta: {
                    title: "編輯白山雲證書SSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/baishan/index"),
                path: "/users/ssl/baishan",
                name: "users/ssl/baishan",
                meta: {
                    title: "白山雲",
                    menu_type: 2,
                    is_show: 1,
                    icon: "CodepenCircleOutlined"
                }
            },
            {
                component: () => import("@/views/users/ssl/wangsu/edit"),
                path: "/users/ssl/wangsu/add",
                name: "users/ssl/wangsu/add",
                meta: {
                    title: "添加網宿證書SSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/wangsu/edit"),
                path: "/users/ssl/wangsu/edit",
                name: "users/ssl/wangsu/edit",
                meta: {
                    title: "編輯網宿證書SSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/wangsu/index"),
                path: "/users/ssl/wangsu",
                name: "users/ssl/wangsu",
                meta: {
                    title: "網宿",
                    menu_type: 2,
                    is_show: 1,
                    icon: "SlackOutlined"
                }
            },
            {
                component: () => import("@/views/users/ssl/aliyun/edit"),
                path: "/users/ssl/aliyun/add",
                name: "users/ssl/aliyun/add",
                meta: {
                    title: "添加阿里雲證書SSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/aliyun/edit"),
                path: "/users/ssl/aliyun/edit",
                name: "users/ssl/aliyun/edit",
                meta: {
                    title: "編輯阿里雲證書SSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/aliyun/index"),
                path: "/users/ssl/aliyun",
                name: "users/ssl/aliyun",
                meta: {
                    title: "阿里雲",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AliyunOutlined"
                }
            },
            {
                component: () => import("@/views/users/ssl/tencent/edit"),
                path: "/users/ssl/tencent/add",
                name: "users/ssl/tencent/add",
                meta: {
                    title: "添加騰訊雲證書SSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/tencent/edit"),
                path: "/users/ssl/tencent/edit",
                name: "users/ssl/tencent/edit",
                meta: {
                    title: "編輯騰訊雲證書SSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/tencent/index"),
                path: "/users/ssl/tencent",
                name: "users/ssl/tencent",
                meta: {
                    title: "騰訊雲",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AntCloudOutlined"
                }
            },
            {
                component: () => import("@/views/users/ssl/amazon/edit"),
                path: "/users/ssl/amazon/add",
                name: "users/ssl/amazon/add",
                meta: {
                    title: "添加AmazonSSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/amazon/edit"),
                path: "/users/ssl/amazon/edit",
                name: "users/ssl/amazon/edit",
                meta: {
                    title: "編輯AmazonSSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/amazon/index"),
                path: "/users/ssl/amazon",
                name: "users/ssl/amazon",
                meta: {
                    title: "Amazon",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AmazonOutlined"
                }
            },
            {
                component: () => import("@/views/users/ssl/conversant/edit"),
                path: "/users/ssl/conversant/add",
                name: "users/ssl/conversant/add",
                meta: {
                    title: "添加ConversantSSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/conversant/edit"),
                path: "/users/ssl/conversant/edit",
                name: "users/ssl/conversant/edit",
                meta: {
                    title: "編輯ConversantSSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/conversant/index"),
                path: "/users/ssl/conversant",
                name: "users/ssl/conversant",
                meta: {
                    title: "Conversant",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AmazonOutlined"
                }
            },
            {
                component: () => import("@/views/users/ssl/akamai/edit"),
                path: "/users/ssl/akamai/add",
                name: "users/ssl/akamai/add",
                meta: {
                    title: "添加ConversantSSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/akamai/edit"),
                path: "/users/ssl/akamai/edit",
                name: "users/ssl/akamai/edit",
                meta: {
                    title: "編輯AkamaiSSL",
                    menu_type: 2,
                    is_show: 0,
                    icon: ""
                }
            },
            {
                component: () => import("@/views/users/ssl/akamai/index"),
                path: "/users/ssl/akamai",
                name: "users/ssl/akamai",
                meta: {
                    title: "Akamai",
                    menu_type: 2,
                    is_show: 1,
                    icon: "AmazonOutlined"
                }
            }
        ]
    },
]