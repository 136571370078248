

module.exports = [
    {
        path: '/dashboard',
        component: () => import(`@/views/admin/dashboard/index.vue`),
        name: 'dashboard',
        meta: {
            title: '控制檯',
            menu_type: 2,
            is_show: 1,
            icon: "DesktopOutlined"
        }
    },
    // {
    //     component: () => import(`@/views/admin/domain/index.vue`),
    //     path: "/admin/domain",
    //     name: "admin/domain",
    //     meta: {
    //         title: "域名列表",
    //         menu_type: 2,
    //         is_show: 1,
    //         icon: "GlobalOutlined"
    //     }
    // },
    {
        path: "/admin/users",
        name: "admin/users",
        auth: "",
        meta: {
            title: "賬戶管理",
            menu_type: 1,
            is_show: 1,
            icon: "ToolOutlined"
        },
        children: [
            {
                component: () => import("@/views/admin/users/user/index.vue"),
                path: "/admin/users/user",
                name: "admin/users/user",
                meta: {
                    title: "賬戶列表",
                    menu_type: 2,
                    is_show: 1,
                    icon: "SettingOutlined"
                }
            },
            {
                component:  () => import("@/views/admin/users/company/index.vue"),
                path: "/admin/users/company",
                name: "admin/users/company",
                meta: {
                    title: "公司管理",
                    menu_type: 2,
                    is_show: 1,
                    icon: "SettingOutlined"
                }
            }
        ]
    },
]