import request from './request'
import router from '@/router'
import store from '@/store'

import md5 from 'js-md5';

export const Md5 = function(input) {
    return md5(input);
}

export function hasPermission(perms) {
  let hasPermission = false
  let permissions = store.state.btn_auth
  if(permissions[0] === "*"){
     hasPermission = true;
  }
  for(let i = 0, len = permissions.length; i < len; i++) {
    if(permissions[i] === perms) {
      hasPermission = true;
      break
    }
  }
  return hasPermission
}


function DealUrlRequestParams(method = 'get', e = {url: '' , data: {} , header: {}}) {
  let url = "/" + e.url
  let params = e.data
  if(params){
    let keys = Object.keys(params)
    if(keys && keys.length > 0) {
      for(let i in keys) {
        let item = keys[i]
        if(!params[item]) {
          delete params[item]
        }
      }
    }
  }
  if(method == "get") {
      url = url + enableUrl(params)
      params = {}
  }
  return {
    method: method,
    url: url,
    data: params
  }
}

export function RequestUser(method = 'get', e = {url: '' , data: {} , header: {}}) {
  let obj = DealUrlRequestParams(method, e)
  obj.base = "user"
  return request(obj)
}

export function RequestDispatch(method = 'get', e = {url: '' , data: {} , header: {}}) {
  let obj = DealUrlRequestParams(method, e)
  obj.base = "dispatch"
  return request(obj)
}


export function RequestCdn(method = 'get', e = {url: '' , data: {} , header: {}}) {
  let obj = DealUrlRequestParams(method, e)
  obj.base = "cdn"
  return request(obj)
}


function enableUrl(data){
	let url = "";
  if(typeof(data) === "object"){
    let j = 0;
    for( let i in data){
        if(j == 0){
          url += "?"
        }else{
          url += "&"
        }
        url += i+"="+data[i]
        j++
    }
  }
  if(url.indexOf("%") > -1) {
    url = url.replace(/%/g,"%25")
  }
  return url
}

/**
 * 跳转
 * @param {*} e={url,data,hreftype}
 * @param {*} dotype 跳转传参类型
 */
export const openPage = function(e) {
  if (!e.hasOwnProperty('url')) {
		console.log('缺少跳转url')
		return
	}
  if(e.url && typeof e.url === 'number' && !isNaN(e.url)) {
		e.hreftype = 'navigateBack';
	}
  switch (e.hreftype) {
    case 'navigateBack':
      this.$router.go(e.url)
      break;
    default: //navigateTo
      router.push({
        path: e.url,
        query: e.data
      })
  }
}


export function array_values(data = [],field = "") {
  var value = data.map((item)=>{
      return item[field];
  })
  return value
}
